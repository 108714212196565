// import siteLogo from "../assets/images/site-logo.png";
import comlinkmobile from "../assets/images/comlink-mobile.svg";
import comlinktv from "../assets/images/comlink-TV.png";
import comlinkpartner from "../assets/images/comlink-partner-program.svg";
import siteLogo from "../assets/images/comlink-bundle.svg";
import Link from "../assets/images/link.png";
import leadprovider0 from "../assets/images/lead-provider-0.png";
import leadprovider1 from "../assets/images/lead-provider-1.png";
import leadprovider2 from "../assets/images/lead-provider-2.png";
import leadprovider3 from "../assets/images/lead-provider-3.png";
import leadprovider4 from "../assets/images/lead-provider-4.png";
import videoposter from "../assets/images/vide-poster.png";
import sport1 from "../assets/images/sport-1.png";
import sport2 from "../assets/images/sport-2.png";
import sport3 from "../assets/images/sport-3.png";
import sport4 from "../assets/images/sport-4.png";
import sport5 from "../assets/images/sport-5.png";
import sport6 from "../assets/images/sport-6.png";
import sport7 from "../assets/images/sport-7.png";
import zipicon from "../assets/images/zip-icon.png";
import map from "../assets/images/map-imag.png";
import footerLogo from "../assets/images/comlink-bundle-white.png";
import comlinkPartnerProgram from "../assets/images/comlink-partner.svg";
import comlinkMobility from "../assets/images/comlink-bundle.png";
import optmimumWhiteLogo from "../assets/images/optmimumWhiteLogo.png";
import optmimumblackLogo from "../assets/images/opt-logo.png";
import bluemobile from "../assets/images/blue-mobile.png";
import spvisa from "../assets/images/sp-visa.png";
import step1img from "../assets/images/step1-img.png";
import smallMobile from "../assets/images/small-mobile.png";
import smallTablet from "../assets/images/small-tablet.png";
import giftCard from "../assets/images/visa-giftcard.png";

import smalltv from "../assets/images/tv.svg";
import smallinternet from "../assets/images/internet.svg";
import smallphone from "../assets/images/phone.svg";
import smallwhitetv from "../assets/images/small-white-tv.png";
import smallwhiteinternet from "../assets/images/small-white-internet.png";
import smallwhitephone from "../assets/images/small-white-phone.png";
import getfree from "../assets/images/get-free.png";
import verizon from "../assets/images/verizon.png";
import hughesnet from "../assets/images/hughesnet.png";
import homeinternet from "../assets/images/home-internet.png";
import astound from "../assets/images/astound.png";
import noDeal from "../assets/images/no-deal.png";
import comlinklogo from "../assets/images/comlink.svg";
import wirelessBlackImg from "../assets/images/wirelessignal.png"
import wirelessWhiteImg from "../assets/images/wirelesswhite-signal.png"
import bannerupdated from "../assets/images/banner-updated-img.png"
import fivegnetwork from "../assets/images/5g-network.jpeg"
import quantumMaxLogo from "../assets/images/quantum-max-logo.png"
import quantumMaxLogoSmall from "../assets/images/quantum-max-logo-small.png"
import tMobile from "../assets/images/tmobile-flyer.png"

export const Images = {
    comlinklogo,
    siteLogo,
    comlinkmobile,
    comlinktv,
    comlinkpartner,
    footerLogo,
    Link,
    leadprovider0,
    leadprovider1,
    leadprovider2,
    leadprovider3,
    leadprovider4,
    videoposter,
    sport1,
    sport2,
    sport3,
    sport4,
    sport5,
    sport6,
    sport7,
    zipicon,
    map,
    comlinkPartnerProgram,
    comlinkMobility,
    // comlinkTV,
    optmimumWhiteLogo,
    optmimumblackLogo,
    bluemobile,
    spvisa,
    step1img,
    smallMobile,
    smallTablet,
    giftCard,
    smalltv,
    smallinternet,
    smallphone,
    smallwhitetv,
    smallwhiteinternet,
    smallwhitephone,
    getfree,
    homeinternet,
    astound,
    verizon,
    hughesnet,
    noDeal,
    wirelessBlackImg,
    wirelessWhiteImg,
    bannerupdated,
    fivegnetwork,
    quantumMaxLogo,
    quantumMaxLogoSmall,
    tMobile
};
